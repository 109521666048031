export default {
  blogerName: 'CASINO SKILL',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/dedivnuk',
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/casino_skill?sr=a',
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@casinoskill2.0?si=ovUaLkeRfWyLRogm',
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@CASINO_SKILL?si=3xHKZNPi8p-ZprIL',
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irrs.com/cf6fda56d',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/ca84beb20',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs.com/ce9a88741',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs.com/c06ef04ff',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://iz-ir23.com/cd3eac989',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/cf3f2735e',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-egikvdtdev.com/c55070cb8',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-mutlukir.com/c52f4b344',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-tmmpgchred.com/c52f9b485',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-nxoyfjmrn.com/cccad1730',
      gameTitle: 'Kali Rox (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs.com/cc6211a10',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>SKILL</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'SKILL',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  bonus100: 'Bônus 100%',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
